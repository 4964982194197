import SecureWrap from 'components/Common/Authorization/SecureWrap';
import DualListBox from 'components/Common/FormElements/DualListBox';
import Input from 'components/Common/FormElements/Input';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';
import Icon from 'components/Common/Icons';
import Instruction from 'components/Common/Instruction';
import Loading from 'components/Common/Loading';
import Paper from 'components/Common/Paper';
import SaveButtons from 'components/Common/SaveButtons';
import ScrollToTop from 'components/Common/ScrollToTop';
import FiscalAgentSelectBox from 'components/Common/SelectBoxes/FiscalAgentSelectBox';
import FiscalYearSelectBox from 'components/Common/SelectBoxes/FiscalYearSelectBox';
import Table from 'components/Common/Table';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import ToastNotification from 'components/Common/Toast';
import { FlexGroup, FlexRow } from 'layouts';
import { find, get, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { cGetContact } from 'redux/universalcontacts/universalcontacts.selectors';
import { readConsortiaMembers } from 'services/grants/consortium.services';
import {
  Consortium,
  ConsortiumMember,
  DropdownItem,
  FiscalAgent,
  FiscalYear,
  UniversalContact,
} from 'types';
import {
  compose,
  converge,
  mergeObjects,
  prop,
  renderData,
} from 'util/objectUtility';
import { getUserEmail, getUserPhone } from 'util/universalcontact.utility';
import { ConsortiumValidations } from 'validations/consortium.validations';

type ConsortiumProps = {
  consortium: Consortium;
  contacts: UniversalContact[];
  fiscalAgents: FiscalAgent[];
  fiscalAgentsList: DropdownItem[];
  loadFiscalAgents: Function;
  newConsortiumId: number;
  saveConsortia: Function;
  setNewError: Function;
  isFetching: boolean;
  loggedInUser: UniversalContact;
  years: FiscalYear[];
};

export const ConsortiumAddEditComponent: React.FC<ConsortiumProps> = (
  props
) => {
  const {
    consortium,
    fiscalAgents,
    isFetching,
    loadFiscalAgents,
    saveConsortia,
    loggedInUser,
    years,
  } = props;

  // --[ dependencies ]--------------------------------------------------------
  const { consortiumId: cId, fiscalYear }: any = useParams();
  const consortiumId = Number(cId);
  const {
    getError,
    getFieldValid,
    isValid,
    validate,
    validateIfTrue,
    validateAll,
    validationErrors,
  } = ConsortiumValidations();

  // --[ local state ]---------------------------------------------------------
  const [consortiumState, setConsortiumState] = useState<Consortium>(
    consortium
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [allMembers, setAllMembers] = useState<ConsortiumMember[]>([]);

  // redux selector
  const getUCConcatInfo = compose(
    useSelector,
    converge(cGetContact, [
      compose(prop('primaryContactId'), prop('fiscalAgent')),
    ])
  );
  const selectedPrimaryUCContact = getUCConcatInfo(consortiumState);

  // --[ component logic ]-----------------------------------------------------
  const isNew: boolean = consortiumId === -1;
  const isLocked = () => {
    const ownerId = owner ? owner : 0;
    return ownerId !== loggedInUser.universalContactId;
  };

  const getMembers = (year: number) => {
    setLoading(true);
    readConsortiaMembers(year)
      .then((results) => {
        setAllMembers(results);
      })
      .catch(() => {
        toast(
          <ToastNotification
            status="error"
            message="Unable to load available consortium members.  Please try again."
          />
        );
      })
      .finally(() => setLoading(false));
  };

  const onConsortiumChange = (
    data: Partial<Consortium>,
    key: keyof Consortium,
    value: any
  ) => {
    validateIfTrue(key, value, consortiumState);
    setConsortiumState(mergeObjects<Consortium>(consortiumState, data));
  };

  const onFiscalYearSelectChange = (data: Partial<Consortium>) => {
    const fiscalYear = find(years, (item) => {
      return Number(item.year) === Number(data.fiscalYear);
    });
    if (fiscalYear) {
      setConsortiumState({ ...consortiumState, fiscalYear });
    }
  };

  // for fiscalAgent select box
  const onFiscalAgentSelectChange = (data: any, key: any, value: any) => {
    const fiscalAgent = find(fiscalAgents, { fiscalAgentId: value });
    if (fiscalAgent) {
      const updated = mergeObjects<Consortium>(consortiumState, {
        fiscalAgent,
      });
      setConsortiumState(updated);
    }
  };

  const handleUpdateSelectedMembers = (members: ConsortiumMember[]) => {
    const updated = mergeObjects<Consortium>(consortiumState, { members });
    setConsortiumState(updated);
  };

  const validateAndSave = () => {
    return validateAll(consortiumState)
      ? saveConsortia(consortiumState)
      : Promise.reject('Not all validations passed. Please check the form.');
  };

  // --[ lifecycle ]-----------------------------------------------------------
  // load data
  useEffect(() => {
    const getYear = compose(prop('year'), prop('fiscalYear'));
    const year = getYear(consortiumState);
    year && getMembers(year);
  }, [consortiumState.fiscalYear]); // eslint-disable-line

  // load data
  useEffect(() => {
    loadFiscalAgents();
  }, [loadFiscalAgents]);

  // update consortium information in state if change from connect detected
  useEffect(() => {
    if (isNew) {
      const fy = find(years, (item) => {
        return Number(item.year) === Number(fiscalYear);
      });
      setConsortiumState(
        mergeObjects<Consortium>(consortium, { fiscalYear: fy })
      );
    } else {
      setConsortiumState(consortium);
    }
  }, [isNew, consortium, fiscalYear, years]);

  // --[ display logic ]-------------------------------------------------------
  const owner = get(consortiumState.fiscalAgent, ['primaryContactId']);

  const CONSORTIUM_CONTACT_HEADERS: TableColumnProps[] = [
    {
      name: 'First Name',
      cell: prop('firstName'),
      selector: 'firstName',
      sortable: true,
    },
    {
      name: 'Last Name',
      cell: prop('lastName'),
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'Contact Phone',
      cell: getUserPhone,
      selector: 'phone',
      sortable: true,
    },
    {
      name: 'Contact Email',
      cell: getUserEmail,
      selector: 'email',
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <Loading isActive={some([loading, isFetching])} />

      <ScrollToTop />
      <Paper>
        <Instruction
          title={
            consortiumState.consortiumId < 0
              ? 'Create New Consortium'
              : `Edit ${consortiumState.consortiumName}`
          }
        >
          {consortiumState.consortiumId < 0 ? (
            <p>
              Complete all required fields before moving to the next section.
              Disabled fields will auto-populate as you complete the form. Use
              "Save New Consortium" to save the consortium and return to the
              consortium dashboard. Use "Cancel" to abandon your changes and
              return to the consortium dashboard.
            </p>
          ) : (
            <p>
              Note: To edit a pre-existing consortium, select and update the
              necessary fields. Disabled fields will auto-populate as you
              complete the form. Once you have finished making changes, use the
              "Why did you make this change" field to provide a comment
              explaining the changes. Use "Save Consortium Updates" to save the
              consortium and return to the consortium dashboard. Use "Cancel" to
              abandon your changes and return to the consortium dashboard.
            </p>
          )}
        </Instruction>
      </Paper>
      <Paper>
        <SecureWrap component="grantsconsortia" isLocked={isLocked()}>
          <Instruction title="Consortium Details">
            <p>
              Fill in all required fields before moving to the Fiscal Agent
              Primary Contact section.
            </p>
            <p className="u-margin-top--small">
              <span className="u-color-red">Note:</span> If you make changes to
              the "Fiscal Agent" field for the consortium for the current fiscal
              year, the database will automatically pull the Perkins Primary
              Contact associated with the new fiscal agent contact and display
              it below. Additionally, although the fiscal agent will not be
              updated on prior year records in the database, the current fiscal
              agent primary contact is who will show on every record, regardless
              of fiscal year, for this consortium. It is a dynamic field that
              pulls the current person. There is a history table in the database
              that accurately captures the correct contact for the fiscal year,
              which is what will show in the Consortium member reports by fiscal
              year in the reports section of the site. If you need additional
              information, work with the CTE Director of Accountability to query
              the database directly.
            </p>
          </Instruction>
          {consortiumId > 0 && (
            <FlexRow>
              <Toggle
                name="status"
                label="Consortium is Active"
                description="This consortium is active."
                value={consortiumState.status}
                onChange={onConsortiumChange}
              />
            </FlexRow>
          )}
          <FlexRow>
            <Input
              name="consortiumName"
              value={consortiumState.consortiumName}
              onChange={onConsortiumChange}
              errorMessage={getError('consortiumName')}
              onBlur={() => {
                validate('consortiumName', consortiumState.consortiumName);
              }}
              valid={getFieldValid('consortiumName')}
            />
            <div
              className="form__group"
              onBlur={() => {
                validate('fiscalAgent', consortiumState.fiscalAgent);
              }}
            >
              <FiscalAgentSelectBox
                errorMessage={getError('fiscalAgent')}
                selection={consortiumState.fiscalAgent.fiscalAgentId}
                onChange={onFiscalAgentSelectChange}
                valid={getFieldValid('fiscalAgent')}
              />
            </div>
            <div
              className="form__group"
              onBlur={() => {
                validate('fiscalYear', consortiumState.fiscalYear);
              }}
            >
              <FiscalYearSelectBox
                onChange={onFiscalYearSelectChange}
                errorMessage={getError('fiscalYear')}
                selection={consortiumState.fiscalYear.year}
                valid={getFieldValid('fiscalYear')}
              />
            </div>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <p className="form__label">Institution Code</p>
              <p className="input-display">
                {renderData(consortiumState.fiscalAgent.institutionCode)}
              </p>
            </FlexGroup>
            <FlexGroup>
              <p className="form__label">Vendor Code</p>
              <p className="input-display">
                {renderData(consortiumState.fiscalAgent.vendorCode)}
              </p>
            </FlexGroup>
            <FlexGroup>
              <p className="form__label">Unique Entity Identifier (UEI)</p>
              <p className="input-display">
                {renderData(consortiumState.fiscalAgent.duns)}
              </p>
            </FlexGroup>
          </FlexRow>
        </SecureWrap>
      </Paper>
      <Paper>
        <Instruction title="Fiscal Agent Primary Contact">
          <p>
            Select a Primary Contact for the consortium in the search field
            below. If the contact you are looking for is not listed, you will
            need to create or update their information in Universal Contacts and
            ensure they are associated with the Fiscal Agent you have selected.
          </p>
        </Instruction>
        <FlexRow>
          <FlexGroup>
            <Table
              noDataComponent={
                <h3>
                  There is no perkins primary contact set for the selected
                  fiscal agent: {consortiumState.fiscalAgent.name}
                </h3>
              }
              data={[selectedPrimaryUCContact]}
              columns={CONSORTIUM_CONTACT_HEADERS}
            />
          </FlexGroup>
        </FlexRow>
      </Paper>
      <Paper>
        <Instruction title="Consortium Members">
          {consortiumState.consortiumId < 0 ? (
            <div>
              <p className="u-margin-bottom--small">
                Add or remove consortium members by scrolling to and clicking on
                the Name of the member. Click the ‘Add’ or ‘Remove’ button to
                create your membership list.
              </p>
              <p className="u-error">
                It is possible to add a member to more than one consortium, so
                make sure to update each applicable consortium.
              </p>
            </div>
          ) : (
            <div>
              <p>
                Add or remove consortium members by scrolling to and clicking on
                the name of the member. Click the ‘Add’ or ‘Remove’ button to
                create your membership list.
              </p>
            </div>
          )}
        </Instruction>
        <SecureWrap
          owner={owner && owner > 0 ? owner : undefined}
          component="grantsconsortia"
          requireEdit={true}
        >
          <DualListBox
            disable={{
              selector: 'wasSubmitted',
              value: true,
            }}
            disableOverrideRoles={['admin', 'perkinsplanmanager']}
            data={allMembers}
            dataLabel="Available Members"
            displayName={(member: ConsortiumMember) => {
              return (
                <React.Fragment>
                  <div
                    className="u-flex"
                    style={{
                      alignItems: 'center',
                      opacity: `${member.wasSubmitted ? '0.6' : '1'}`,
                    }}
                  >
                    <p
                      style={{
                        marginRight: '.6rem',
                        textTransform: 'capitalize',
                      }}
                    >
                      {member.type}:
                    </p>
                    <p className="u-flex-grow-1">{member.name}</p>
                    {member.wasSubmitted ? (
                      <Icon className="list-selector__icon" name="lock" />
                    ) : null}
                  </div>
                </React.Fragment>
              );
            }}
            selectedData={consortiumState.members}
            selectedDataLabel="Consortium Members"
            selector="uniqueIdentifier"
            sortBySelectors={['type', 'name']}
            onChange={handleUpdateSelectedMembers}
          />
          {consortiumId > 0 && (
            <FlexRow>
              <Textarea
                name="comment"
                label="Why did you make this change?"
                value={consortiumState.comment}
                onChange={onConsortiumChange}
                errorMessage={getError('comment')}
                onBlur={() => {
                  validate('comment', consortiumState.comment, consortiumState);
                }}
                valid={getFieldValid('comment')}
              />
            </FlexRow>
          )}
        </SecureWrap>
      </Paper>
      <Paper>
        <SaveButtons
          cancelUrl="/grants/consortium"
          disabled={!isValid}
          saveData={validateAndSave}
          saveText={
            consortiumState.consortiumId < 1
              ? 'Save New Consortium'
              : 'Save Consortium Updates'
          }
          saveUrl="/grants/consortium"
          validationErrors={validationErrors}
        />
      </Paper>
    </React.Fragment>
  );
};
