import React, { useState, useEffect } from 'react';
import { FlexRow, FlexGroup, ButtonContainer } from 'layouts';
import {
  GrantParticipationMember,
  Consortium,
  AzureUploadParameters,
  UniversalContact,
} from 'types';
import {
  getContactFullName,
  getUserEmail,
  getUserPhone,
} from 'util/universalcontact.utility';
import { compose, mergeObjects, renderData, prop } from 'util/objectUtility';
import { TableColumnProps } from 'components/Common/Table/Table.component';
import { useDispatch } from 'react-redux';
import { upsertGrantParticipants } from 'redux/grants/grantparticipant';
import { isEmpty, every } from 'lodash';
import AzureDownloader from 'components/Common/AzureFileStorage/AzureDownloader';
import Button from 'components/Common/FormElements/Button';
import IconButton from 'components/Common/IconButton';
import Instruction from 'components/Common/Instruction';
import Paper from 'components/Common/Paper';
import Table from 'components/Common/Table';
import Textarea from 'components/Common/FormElements/Textarea';
import Toggle from 'components/Common/FormElements/Toggle';

type EditParticipantProps = {
  consortiumInfo: Consortium;
  participantId: number;
  participant: GrantParticipationMember;
  close: Function;
};

export const EditParticipantComponent: React.FC<EditParticipantProps> = (
  props
) => {
  const { close, consortiumInfo, participant } = props;
  const {
    consortiumId,
    consortiumName,
    contacts,
    name,
    isStandalone,
    type,
  } = participant;

  const dispatch = useDispatch();

  const [state, setState] = useState<GrantParticipationMember>({
    ...participant,
  });

  const getParticipantType = isStandalone ? 'Standalone' : 'Consortium';

  const onChange = (
    data: Partial<GrantParticipationMember>,
    key: keyof GrantParticipationMember,
    value: any
  ) => {
    const updated = mergeObjects<GrantParticipationMember>(state, data);
    setState(updated);
  };

  const uploadParams: AzureUploadParameters = {
    container: 'grants',
    folder: '',
  };

  const handleSave = () => {
    const payload = {
      ...participant,
      ...state,
    };
    dispatch(upsertGrantParticipants(payload));
    close();
  };
  const getExemptionReason = () => {
    switch (state.exemptionReason) {
      case 1:
        return 'No consortium in region';
      case 2:
        return 'Distance and / or Terrain limit access to substitutes';
      case 3:
        return 'Participation as Exempt is a grant condition ';
      default:
        return '';
    }
  };

  const FullName = compose(renderData, getContactFullName);

  const Title = compose(renderData, prop('title'));

  const Email = compose(renderData, getUserEmail);

  const Phone = compose(renderData, getUserPhone);

  const renderNavIcon = (row: UniversalContact) => {
    return (
      <React.Fragment>
        <IconButton
          name="launchOpen"
          tooltip="View Grant Application Details"
          asLink={true}
          to={`/contacts/${row.universalContactId}`}
        />
      </React.Fragment>
    );
  };

  const UC_COLUMNS: TableColumnProps[] = [
    {
      name: 'Name',
      cell: FullName,
      sortable: true,
      selector: FullName,
    },
    {
      name: 'Title',
      cell: Title,
      sortable: true,
      selector: Title,
    },
    {
      name: 'Email',
      cell: Email,
      sortable: true,
      selector: Email,
    },
    {
      name: 'Phone',
      cell: Phone,
      sortable: true,
      selector: Phone,
    },
    {
      name: 'View',
      cell: renderNavIcon,
    },
  ];

  useEffect(() => {
    setState({
      ...participant,
    });
  }, [participant]);

  return (
    <React.Fragment>
      <Paper>
        <Instruction title="Edit Participant">
          <p className="u-margin-bottom-medium">
            This page enables you to update the status of the participant’s
            application and to enter comments describing the rationale for
            making a change. Use toggles to change participant’s application
            status to active or inactive. If Participation Type is ‘Standalone’,
            you will also note that you may toggle whether the participant is
            exempt.
          </p>
          <p>
            CAUTION: Remember to click ‘Save and Close’ to enter changes into
            the database.
          </p>
        </Instruction>

        <FlexRow>
          <FlexGroup>
            <p className="form__label">Institution Name</p>
            <p className="input-display">{renderData(name)}</p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Consortium</p>
            <p className="input-display">{renderData(consortiumName)}</p>
          </FlexGroup>
        </FlexRow>

        <FlexRow>
          <FlexGroup>
            <p className="form__label">Type</p>
            <p className="input-display">{renderData(type)}</p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Participation Type</p>
            <p className="input-display">{renderData(getParticipantType)}</p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Participating</p>
            <Toggle
              centerWithRow={true}
              description="Is Participating"
              name="isParticipating"
              noLabel={true}
              onChange={onChange}
              value={state.isParticipating ? state.isParticipating : false}
            />
          </FlexGroup>

          {getParticipantType !== 'Consortium' ? (
            <FlexGroup>
              <p className="form__label">Exempt</p>
              <Toggle
                centerWithRow={true}
                description="Is Exempt"
                name="isExempt"
                noLabel={true}
                onChange={onChange}
                value={state.isExempt ? state.isExempt : false}
              />
            </FlexGroup>
          ) : (
            <FlexGroup>
              <div>&nbsp;</div>
            </FlexGroup>
          )}
        </FlexRow>

        <FlexRow>
          <FlexGroup>
            <p className="form__label">Consortium ID</p>
            <p className="input-display">{renderData(consortiumId)}</p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Institution Code</p>
            <p className="input-display">
              {renderData(consortiumInfo.fiscalAgent.institutionCode)}
            </p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Vendor Code</p>
            <p className="input-display">
              {renderData(consortiumInfo.fiscalAgent.vendorCode)}
            </p>
          </FlexGroup>
          <FlexGroup>
            <p className="form__label">Unique Entity Identifier (UEI)</p>
            <p className="input-display">
              {renderData(consortiumInfo.fiscalAgent.duns)}
            </p>
          </FlexGroup>
        </FlexRow>
        <FlexRow>
          <Textarea
            onChange={onChange}
            name="comment"
            value={state.comment ? state.comment : ''}
          />
        </FlexRow>

        {every([
          state.exemptionReason > 0,
          !isEmpty(state.exemptionFilePath),
        ]) && (
          <FlexRow>
            <FlexGroup>
              <p className="form__label">Reason for standalone exempt status</p>
              <p className="input-display">{getExemptionReason()}</p>
            </FlexGroup>

            <FlexGroup>
              <p className="form__label">Letter from Superintendent</p>
              <AzureDownloader
                editable={false}
                onClose={() => {}}
                uploadParams={uploadParams}
                filepath={state.exemptionFilePath}
              />
            </FlexGroup>
          </FlexRow>
        )}
        {!isEmpty(state.changeExplanation) && (
          <FlexRow>
            <FlexGroup>
              <p className="form__label">Status Change Explanation</p>
              <p className="input-display">{state.changeExplanation}</p>
            </FlexGroup>
          </FlexRow>
        )}
      </Paper>
      <Paper>
        <h2>Program Contact(s)</h2>
        <Table columns={UC_COLUMNS} data={contacts} />
      </Paper>
      <Paper>
        <ButtonContainer>
          <FlexRow>
            <FlexGroup>
              <Button
                asLink={false}
                text="Cancel"
                onClick={close}
                className="form__btn--cancel"
              />
            </FlexGroup>
            <FlexGroup>
              <Button
                asLink={false}
                text="Save and close"
                onClick={handleSave}
                className="form__btn--submit"
              />
            </FlexGroup>
          </FlexRow>
        </ButtonContainer>
      </Paper>
      <div style={{ height: '16rem' }} />
    </React.Fragment>
  );
};
